// <copyright file="app-routing.module.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, PreloadingStrategy, Route } from '@angular/router';
import { CanActivateSdkApiFilesRoute, CanActivateSdkLicensesRoute, CanActivateSdkLogsRoute } from './shared/guards';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { Observable, of } from 'rxjs';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    { path: 'home', redirectTo: '', pathMatch: 'full' },
    {
        path: 'sdkLogs',
        loadChildren: () => import('./sdklogs/sdklogs.module').then(m => m.SdkLogsModule),
        canActivate: [CanActivateSdkLogsRoute]
    },
    {
        path: 'sdkApiFiles',
        loadChildren: () => import('./sdkapifiles/sdkapifiles.module').then(m => m.SdkApiFilesModule),
        canActivate: [CanActivateSdkApiFilesRoute]
    },
    {
        path: 'sdkLicenses',
        loadChildren: () => import('./sdklicenses/sdklicenses.module').then(m => m.SdkLicensesModule),
        canActivate: [CanActivateSdkLicensesRoute]
    },
    {
        path: 'venuedata',
        loadChildren: () => import('./venuedata/venuedata.module').then(m => m.VenueDataModule),
        canActivate: [CanActivateSdkLogsRoute]
    },
    {
        path: 'devices',
        loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule),
        canActivate: [CanActivateSdkLogsRoute]
    },
    { path: '**', component: PageNotFoundComponent }
];

@Injectable()
export class PreloadSelectedModuleList implements PreloadingStrategy {
    public preload(route: Route, load: Function): Observable<any> {
        return route.data && route.data.preload ? load() : of(null);
    }
}

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
    declarations: [],
    providers: [
        CanActivateSdkApiFilesRoute,
        CanActivateSdkLicensesRoute,
        CanActivateSdkLogsRoute,
        PreloadSelectedModuleList
    ]
})
export class AppRoutingModule { }
