// <copyright file="floors.service.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { DeviceSimpleDTO } from '../../dtos';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class DevicesService {
    private baseUrl = 'api/CameraCalibration';

    constructor(private httpClient: HttpClient) { }

    public getDevices(): Observable<DeviceSimpleDTO> {
        //     return of(
        //         {
        //             FileName: 'Somefile.csv',
        //             Date: '2023-12-28 11:30:20',
        //             SupportedDevices: [
        //                 {
        //                     Platform: 'Andriod',
        //                     Brand: 'Samsung',
        //                     Devices: 'Samsung Galaxy A5456',
        //                     Validated: 'SM-A546B',
        //                     NotValidated: 'SM-A546*,; E, V, W, D, J, 0, U, U1',
        //                     Remarks: 'Limited navigation performance',
        //                 },
        //                 {
        //                     Platform: 'Andriod',
        //                     Brand: 'Oppo',
        //                     Devices: 'Oppo As5456',
        //                     Validated: 'SM-A546B',
        //                     NotValidated: 'SM-A546*,; E, V, W, D, J, 0, U, U1',
        //                     Remarks: 'Limited navigation performance',
        //                 },
        //                 {
        //                     Platform: 'Iphone',
        //                     Brand: 'Apple',
        //                     Devices: 'Iphone 16',
        //                     Validated: 'SM-A546B',
        //                     NotValidated: 'SM-A546*,; E, V, W, D, J, 0, U, U1',
        //                     Remarks: 'Limited navigation performance',
        //                 },
        //             ],
        //         }
        //     ).pipe(delay(1500))
        // };
        return this.httpClient.get<DeviceSimpleDTO>(this.baseUrl + '/CameraCalibration');
    }
}
