// <copyright file="floors.effect.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DevicesService } from '../../../services';
import * as devicesActions from '../../actions';

@Injectable()
export class DevicesEffect {
    public doLoadDevicesData: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(devicesActions.devicesActionTypes.DEVICES_LOAD),
            map((action: devicesActions.DevicesLoadAction) => action['payload']),
            mergeMap(state =>
                this.devicesService.getDevices().pipe(
                    map(data => new devicesActions.DevicesLoadSuccessAction(data)),
                    catchError(error => of(new devicesActions.DevicesLoadFailAction(error))),
                ),
            ),
        ),
    );

    constructor(private actions: Actions, private devicesService: DevicesService) { }
}
