// <copyright file="index.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { ActionReducerMap } from '@ngrx/store';
import * as fromCustomers from './customers/customers.reducer';
import * as fromLicenses from './licenses/licenses.reducer';
import * as fromVenues from './venues/venues.reducer';
import * as fromFloors from './floors/floors.reducer';
import * as fromLayouts from './layouts/layouts.reducer';
import * as fromDevices from './devices/devices.reducer';

export interface ISharedState {
    customers: fromCustomers.ICustomerState;
    venues: fromVenues.IVenueState;
    licenses: fromLicenses.ILicenseState;
    floors: fromFloors.IFloorState;
    layouts: fromLayouts.ILayoutState;
    devices: fromDevices.IDeviceState;
}

export const reducers: ActionReducerMap<ISharedState> = {
    customers: fromCustomers.reducer,
    venues: fromVenues.reducer,
    licenses: fromLicenses.reducer,
    floors: fromFloors.reducer,
    layouts: fromLayouts.reducer,
    devices: fromDevices.reducer
};
