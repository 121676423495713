// <copyright file="devices.sandbox.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import * as store from '../../store';
import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromDevices from '../../store/actions';
import { ErrorHandlerService } from '../../utility/error-handler/error-handler.service';
import { Subscription } from 'rxjs';

@Injectable()
export class DevicesSandbox implements OnDestroy {
    public devices = this.appState.pipe(select(store.getDevicesData));
    public devicesLoading = this.appState.pipe(select(store.getDevicesLoading));
    public devicesFailed = this.appState.pipe(select(store.getDevicesFailed));
    public devicesSuccess = this.appState.pipe(select(store.getDevicesSuccess));

    private errorSubscription: Subscription;

    constructor(protected appState: Store<store.ISharedState>, private errorHandlerService: ErrorHandlerService) {
        this.setupErrorSubscription();
    }
    public ngOnDestroy(): void {
        if (this.errorSubscription) {
            this.errorSubscription.unsubscribe();
        }
    }

    public loadDevices(): void {
        this.appState.dispatch(new fromDevices.DevicesLoadAction());
    }

    public clearDevices(): void {
        this.appState.dispatch(new fromDevices.DevicesClearAction());
    }

    private setupErrorSubscription(): void {
        this.errorSubscription = this.devicesFailed.subscribe((failed: boolean) => {
            if (failed) { this.errorHandlerService.addToErrors('Something went wrong while loading the devices.'); }
        });
    }
}
