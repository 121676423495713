// <copyright file="floors.action.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { DeviceSimpleDTO } from '../../../dtos';
import { Action } from '@ngrx/store';
import { type } from '../../../utility/type/type.helper';

export const devicesActionTypes = {
    DEVICES_LOAD: type('[DEVICES] Load'),
    DEVICES_LOAD_FAIL: type('[DEVICES] Load Fail'),
    DEVICES_LOAD_SUCCESS: type('[DEVICES] Load Success'),
    DEVICES_CLEAR: type('[DEVICES] Clear')
};

export class DevicesLoadAction implements Action {
    public type = devicesActionTypes.DEVICES_LOAD;

    constructor() { }
}

export class DevicesLoadSuccessAction implements Action {
    public type = devicesActionTypes.DEVICES_LOAD_SUCCESS;

    constructor(public payload: DeviceSimpleDTO) { }
}

export class DevicesLoadFailAction implements Action {
    public type = devicesActionTypes.DEVICES_LOAD_FAIL;

    constructor(public payload: any) { }
}

export class DevicesClearAction implements Action {
    public type = devicesActionTypes.DEVICES_CLEAR;

    constructor() { }
}

export type DevicesActions
    = DevicesLoadAction
    | DevicesLoadFailAction
    | DevicesLoadSuccessAction
    | DevicesClearAction;
