// <copyright file="floors.selectors.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromDevices from '../../reducers/devices/devices.reducer';

export const getDevicesState = (state: fromFeature.ISharedState) => state.devices;

export const getDevicesData = createSelector(
    getDevicesState,
    fromDevices.getDevices
);

export const getDevicesLoading = createSelector(
    getDevicesState,
    fromDevices.getLoading
);

export const getDevicesSuccess = createSelector(
    getDevicesState,
    fromDevices.getSuccess
);

export const getDevicesFailed = createSelector(
    getDevicesState,
    fromDevices.getFailed
);
