// <copyright file="index.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { LayoutsEffect } from './layouts/layouts.effect';
import { LicensesEffect } from './licenses/licenses.effect';
import { VenuesEffect } from './venues/venues.effect';
import { CustomersEffect } from './customers/customers.effect';
import { FloorsEffect } from './floors/floors.effect';
import { DevicesEffect } from './devices/devices.effect';

export const effects: any[] = [
    CustomersEffect,
    VenuesEffect,
    LicensesEffect,
    FloorsEffect,
    LayoutsEffect,
    DevicesEffect
];
