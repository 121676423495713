// <copyright file="app.module.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ConfigurationService } from './shared/utility/configuration/configuration.service';
import { ContainersModule } from './shared/containers';
import { CustomerInformationService } from './shared/services/customer-information/customer-information.service';
import {
    CustomersSandbox,
    FloorsSandbox,
    LayoutsSandbox,
    LicensesSandbox,
    VenuesSandbox,
    DevicesSandbox
} from './shared/sandboxes';
import {
    CustomersService,
    FloorsService,
    LayoutsService,
    LicensesService,
    VenuesService,
    DevicesService
} from './shared/services';
import { effects, reducers } from './shared/store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from './environments/environment';
import { ErrorHandlerService } from './shared/utility/error-handler/error-handler.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LocalStorageService } from './shared/utility/localStorage/localStorage.service';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MessageService } from './shared/utility/messages/message.service';
import { RouterModule } from '@angular/router';
import { SecurityService } from './shared/utility/security/security.service';
import { SharedComponentModule } from './shared/components';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { TypeCheckInterceptor } from './shared/interceptors/type-check.interceptor';
import { MsalBroadcastService, MsalInterceptor, MsalService, MSAL_INSTANCE } from 'shared/msal';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from 'shared/msal/constants';
import { InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { MsalGuardConfiguration } from 'shared/msal/msal.guard.config';
import { MsalInterceptorConfig } from 'shared/msal/msal.interceptor.config';

export function MSALInstanceFactory(): IPublicClientApplication {
    return new ConfigurationService().getMSALInstanceFactory();
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
    return new ConfigurationService().getMSALInterceptorConfigFactory();
}

@NgModule({
    providers: [
        CustomersService,
        CustomersSandbox,

        DevicesSandbox,
        DevicesService,

        VenuesService,
        VenuesSandbox,

        LicensesService,
        LicensesSandbox,

        FloorsService,
        FloorsSandbox,

        LayoutsService,
        LayoutsSandbox,

        CustomerInformationService,

        ErrorHandlerService,

        SecurityService,
        ConfigurationService,
        MessageService,
        LocalStorageService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TypeCheckInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useValue: {
                interactionType: InteractionType.Redirect,
            } as MsalGuardConfiguration,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalBroadcastService,
    ],
    imports: [
        BrowserModule,
        RouterModule,
        BrowserAnimationsModule,
        MatInputModule,
        AppRoutingModule,
        SharedComponentModule,
        HttpClientModule,
        ContainersModule,
        MatSnackBarModule,

        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictActionImmutability: false,
                strictActionSerializability: false,
                strictStateImmutability: false,
                strictStateSerializability: false
            }
        }),

        !environment.production ? StoreDevtoolsModule.instrument({
            maxAge: 5
        }) : [],

        EffectsModule.forRoot(effects)
    ],
    declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
