// <copyright file="devices.reducer.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { DeviceSimpleDTO } from '../../../dtos';
import * as actions from '../../actions/devices/devices.action';

export interface IDeviceState {
    loading: boolean;
    success: boolean;
    failed: boolean;
    devices: Array<DeviceSimpleDTO>;
}

export const INITIAL_STATE: IDeviceState = {
    devices: [],
    failed: false,
    loading: false,
    success: false,
};

export function reducer(state = INITIAL_STATE, action: actions.DevicesActions): IDeviceState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case actions.devicesActionTypes.DEVICES_LOAD: {
            return Object.assign({}, state, {
                loading: true
            });
        }

        case actions.devicesActionTypes.DEVICES_LOAD_SUCCESS: {
            const loadAction = action as actions.DevicesLoadSuccessAction;
            return Object.assign({}, state, {
                devices: loadAction.payload,
                success: true,
                failed: false,
                loading: false
            });
        }

        case actions.devicesActionTypes.DEVICES_LOAD_FAIL: {
            return Object.assign({}, state, {
                devices: undefined,
                success: false,
                failed: true,
                loading: false
            });
        }

        case actions.devicesActionTypes.DEVICES_CLEAR: {
            return Object.assign({}, state, INITIAL_STATE);
        }

        default: {
            return state;
        }
    }
}

export const getDevices = (state: IDeviceState) => state?.devices;
export const getLoading = (state: IDeviceState) => state?.loading;
export const getSuccess = (state: IDeviceState) => state?.success;
export const getFailed = (state: IDeviceState) => state?.failed;
